export enum EStatusTituloFinanceiro{
  Pendente = 1,
  Baixado = 2,
  BaixadoParcialmente = 3,
  BaixadoCartao = 4,
  BaixadoCredito = 5,
  Renegociado = 6,
  Excluido = 7,
  AprovacaoPendente = 8,
  Reprovado = 9,
  Vencido = 10,
  Antecipado = 11,
}
